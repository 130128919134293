import {find, sortBy} from "lodash";
import {calcDistance} from "./calculate";

export const GUEST_TAGS = [
    {
        title: "어떤 차가 필요하신가요? (복수선택 가능)",
        tags: [
            {id: "매니저님이랑 상담 후 결정할게요.", tag: "잘 모르겠어요. 매니저님이랑 상담 후 결정할게요.", unique: false, default: true},
            {id: "주말에만 탈 차가 필요해요.", tag: "주말에만 탈 차가 필요해요."},
            {id: "평일에만 탈 차가 필요해요.", tag: "평일에만 탈 차가 필요해요."},
            {id: "매주 탈 요일을 고정해서 타고 싶어요.", tag: "매주 탈 요일을 고정해서 타고 싶어요."},
            {id: "미리 정하지 않고 한달에 10~15일 정도 탈 차가 필요해요.", tag: "미리 정하지 않고 한달에 10~15일 정도 탈 차가 필요해요."},
            {id: "렌터카처럼 몇달 간 혼자 탈 차가 필요해요.", tag: "렌터카처럼 몇달 간 혼자 탈 차가 필요해요."},
            {id: "차가 괜찮으면 호스트의 이용패턴에 맞춰볼게요.", tag: "차가 괜찮으면 호스트의 이용패턴에 맞춰볼게요."},
        ]
    },
];

const 국산차브랜드 = ["현대", "기아", "제네시스", "대우", "쉐보레", "르노", "KG모빌리티", "쌍용"];
export const GUEST_WANTED = [
    {
        title: "브랜드",
        tags: [
            {id: "국산차", tag: "국산차", predicate: v => find(국산차브랜드, b => v?.brand?.includes(b))},
            {id: "외제차", tag: "외제차", predicate: v => !find(국산차브랜드, b => v?.brand?.includes(b))},
        ]
    },
    {
        title: "월이용료",
        tags: [
            {id: "30만원 이하", tag: "30만원 이하", text: "30만원 이하",  max: 300000, predicate: (v, useTypeIds) => {
                let useTypes = v.hostContract.useTypes;
                if (useTypeIds && useTypeIds.length) {
                    useTypes = useTypes.filter(u => find(useTypeIds, (id) => id === u.id));
                }
                return find(useTypes, (u) => u.price <= 300000);
            }},
            {id: "30만원 ~ 60만원", tag: "30만원 ~ 60만원", text: "30만원 ~ 60만원",  min: 300000, max: 600000, predicate: (v, useTypeIds) => {
                let useTypes = v.hostContract.useTypes;
                if (useTypeIds && useTypeIds.length) {
                    useTypes = useTypes.filter(u => find(useTypeIds, (id) => id === u.id));
                }
                return find(useTypes, (u) =>  300000 <= u.price && u.price <= 600000);
            }},
            {id: "60만원 ~ 100만원", tag: "60만원 ~ 100만원", text: "60만원 ~ 100만원", min: 600000, max: 1000000, predicate: (v, useTypeIds) => {
                let useTypes = v.hostContract.useTypes;
                if (useTypeIds && useTypeIds.length) {
                    useTypes = useTypes.filter(u => find(useTypeIds, (id) => id === u.id));
                }
                return find(useTypes, (u) => 600000 <= u.price && u.price <= 1000000)
            }},
            {id: "100만원 이상", tag: "100만원 이상", text: "100만원 이상", min: 1000000, predicate: (v, useTypeIds) => {
                let useTypes = v.hostContract.useTypes;
                if (useTypeIds && useTypeIds.length) {
                    useTypes = useTypes.filter(u => find(useTypeIds, (id) => id === u.id));
                }
                return find(useTypes, (u) => 1000000 <= u.price)
            }},
        ]
    },
    {
        title: "매칭유형",
        tags: [
            {id: "호스트우선형", tag: "호스트우선형", predicate: (v) => find(v.hostContract.useTypes, (u) => u.id === 'PRIORITY_HOST')},
            // {id: "요일지정형", tag: "요일지정형", predicate: (v) => find(v.hostContract.useTypes, (u) => u.id === 'DAY_SELECT')},
            {id: "게스트우선형", tag: "게스트우선형", predicate: (v) => find(v.hostContract.useTypes, (u) => u.id === 'PRIORITY_GUEST')},
        ]
    },
];