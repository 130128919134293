import styled, {css} from "styled-components";
import {FormComponent, PhotoComponent} from "./FormComponent";
import React, {useEffect, useState} from "react";
import BottomDrawer from "./BottomDrawer";
import {PositiveBtn} from "./BottomNavigator";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Dialog from "@mui/material/Dialog";

const InsuranceSet = ({form, onChange, doorive}) => {
    const [open, setOpen] = useState(false);

    /*

                보험사명: _form.보험사명,
                대인한도: _form.대인한도,
                대물한도: _form.대물한도,
                자차여부: _form.자차여부,
                거리당보험료: _form.거리당보험료,
                월보험료인상액: _form.월보험료인상액,
     */
    return <>
        <Wrap>
            <div className="forms">
                <h1>자동차보험</h1>

                <div>
                    <h2 style={{fontSize: '20px'}}>내 보험 정보 (선택)</h2>
                    <p className="big">매칭계약 체결 전에 입력을 완료해주세요.</p>
                </div>
                <FormComponent id="보험사명" title="보험사명" form={form} onChange={onChange} placeholder="ex. 삼성화재" />
                <FormComponent id="연간보험료" title="연간보험료" placeholder="ex. 1,270,300원" helpBtn={true} description={"현재 납부하고 있는 1년치 총 자동차보험료를 입력해주세요.\n게스트가 보험 접수 시 호스트에게 납부하는 ‘위로금’ 계산에 활용됩니다.\n\n※ 캐롯보험 등 월정산형은 월기본보험료에 12개월을 곱하여 계산해주세요."} form={form} onChange={onChange} />
                {/* <FormComponent id="물적할증금액" title="물적할증금액 (대물/자차 할증기준)" placeholder="ex. 200만원" form={form} onChange={onChange} /> */}
                <FormComponent id="대물한도" title="대물 한도" placeholder="ex. 5억원" form={form} onChange={onChange} />
                <FormComponent id="대인한도" title="대인 한도" placeholder="ex. 무제한" form={form} onChange={onChange} />
                <FormComponent id="자차여부" title="자차보험 가입여부" placeholder="O / X" form={form} onChange={onChange} />
                <FormComponent id="거리당보험료" title="거리당보험료 (캐롯보험만 해당)" placeholder="ex. 32.9원/km" helpBtn={true} description={"캐롯손해보험의 경우 호스트/게스트가 각자 주행한 만큼 거리당보험료를 부담합니다. 게스트 추가 시 거리당보험료가 변동되므로, 나중에 매칭이 되면 실제 변동된 값으로 입력해주세요."} form={form} onChange={onChange} />
                <FormComponent id="월보험료인상액" type="textarea" title="월보험료인상액" helpBtn={true} description={"게스트 연령이 30세 미만일 경우 청구할 월보험료인상액을 적어주세요."} placeholder={"[게스트 연령 별 월보험료인상액 예시]\n" +
                    "28세 - 월 6만원 추가\n" +
                    "26세 - 월 12만원 추가\n" +
                    "23세 - 월 20만원 추가\n" +
                    "\n" +
                    "※ 게스트로부터 매칭요청이 들어올 때마다 해당 연령으로 조회한 값을 적어두시는 것을 추천드려요."} form={form} onChange={onChange} rows={8} />

                <div>
                    <h2>운전자범위 변경</h2>
                    <p className="big">매칭이 성사되면 게스트를 추가운전자로 등록하거나 누구나운전으로 변경해주세요.</p>
                    <p className="big">추가운전자 등록이나 누구나운전으로 변경 시 인상되는 보험료는 보통 월 5천원 수준입니다. 게스트 연령이 30세 미만이면 실제 보험료인상액을 별도로 청구할 수 있습니다.</p>
                    <TextBox style={{marginTop: '12px'}}>
                        <p>
                            게스트 사고 시 조치방법 및 위로금<br/><br/>
                            게스트는 보험 처리 시 호스트에게 보험료할증, 차량감가를 보상하는 '위로금'을 지급해야합니다. (자기부담금은 게스트가 별도 납부)
                        </p>
                        <p>책임매니저가 단톡방에 상주하면서 사고 후 처리 과정을 전부 도와드리며, 게스트가 위로금을 납부하지 않을 경우 두리카에서 대신 지급해드리니 걱정마세요!</p>
                        <p>
                            <span className="link" onClick={() => setOpen(true)}>위로금 정책 자세히보기 {'>'}</span>
                        </p>
                    </TextBox>
                </div>

                {/*{doorive && <div>*/}
                {/*    <h2>공유시작가능일</h2>*/}
                {/*    /!*<h3>[2023년 9월 1일] 부터 공유를 시작할 수 있어요.</h3>*!/*/}
                {/*    <FormComponent id="availableDate" type="date" placeholder="공유시작가능일 선택하기" form={form} onChange={onChange} />*/}
                {/*    <p>*/}
                {/*        ⚠️ 매칭 시작이 가능한 날짜를 입력해주세요. 매칭 시 최소 공유기간은 1개월이며, 약정기간이 매월 1개월씩 자동으로 연장됩니다. 연장을 중단하려면 최소 1개월 이전에 해지요청이 필요해요.*/}
                {/*    </p>*/}
                {/*</div>}*/}
                {/*<FormComponent id="dooriveTitle" title="제목" placeholder="예) G80 같이 타실 분 구해요!" form={form} onChange={onChange} />*/}
                {/*<div>*/}
                {/*    <h2>설명</h2>*/}
                {/*    <h3>게스트 이용시 주의사항, 매칭 조건 관련사항 등 게스트에게 추가적으로 하고 싶은 말을 자유롭게 적어주세요.</h3>*/}
                {/*    <FormComponent id="dooriveDescription" type="textarea" rows={6} placeholder="예) 손세차 필수, 스팀세차 불가, 평소 차를 깨끗하게 관리해서 상태가 아주 좋습니다. 주차장 24시간 출입가능해서 편리해요." form={form} onChange={onChange} />*/}
                {/*</div>*/}
            </div>
        </Wrap>

        <Dialog open={open} onClose={() => setOpen(false)}>
            <Drawer1/>
            <PositiveBtn style={{margin: '24px'}} onClick={() => setOpen(false)}>확인</PositiveBtn>
        </Dialog>
        {/* <BottomDrawer open={open} onClose={() => setOpen(false)}>
            <Drawer1/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setOpen(false)}>확인</PositiveBtn>
        </BottomDrawer> */}
    </>
}

const Drawer1 = () => {
    return <>
        <TextBox2>
            <h2 style={{marginBottom: '12px'}}>위로금 정책</h2>
            <ReactMarkdown remarkPlugins={[remarkGfm]} children={`**원칙**

게스트가 차를 사용(점유/관리 포함)하고 있는 동안 발생한 손상은 게스트가 이를 원상복구하는 것을 원칙으로 합니다.  (게스트의 점유/관리 여부는 캘린더의 예약일을 참고하여 실질로 판단합니다.)

원상복구는 **복원**이나 **수리**가 원칙이며, 구조적 손상이나 정상적인 주행에 영향을 미칠 정도가 심각하여 수리로 복구가 불가능한 경우에만 교체가 가능합니다. 복원이나 수리 방법, 교체 여부, 수리장소 등은 호스트와 게스트가 협의로 결정하며, 합의가 이뤄지지 않을 경우 두리카에서 정하는 방법을 따릅니다.

**손상**은 사고 등 주로 물리적인 충격 등으로 발생하거나 현저하게 잘못된 사용/관리로 인한 것으로 한정하며, 일반적인 사용(노후)에 따른 고장 등은 게스트가 책임지지 않습니다.

게스트는 호스트가 가입한 자동차보험의 보장을 받을 수 있습니다. 게스트는 보험 접수 전 호스트의 승인을 받은 후 직접 보험사에 접수할 수 있으며, 호스트는 특별한 사유 없이 이를 거절할 수 없습니다. (보험사 긴급출동은 사전 승인 없이 사용 가능)

각자의 판단에 따라 자동차보험을 접수할 수 있습니다. 단, 게스트가 보험 접수 시에는 호스트에게 위로금을 지급해야합니다. {자차보험 접수 시 보험사에 지급하는 **자기부담금**(통상 20%, 최대 50만원)은 게스트가 별도로 납부합니다.}

**위로금이란?** 

게스트가 보험을 접수하는 대가로 보험료 할증, 차량 감가상각 등 포괄적인 손해에 대한 보상으로 호스트에게 지급하는 금액입니다. 

실제 보험료할증액이나 차량감가액보다 크거나 적을 수 있으며 두리카에서 정하는 계산법을 따릅니다. 단, 호스트가 실제 보험료 할증분을 입증하는 경우 해당금액을 따릅니다.

※ 실제 보험료 할증분은 3년간 발생예정인 할증액에 한하며, 해당기간 동안 할인을 적용받지 못하는만큼의 소극적 손해는 고려하지 않습니다.

※ 호스트의 차량이 여러대인 경우, 다른 차량에 대한 할증분도 입증 시 청구할 수 있습니다.

위로금은 3가지(보험할증위로금, 감가위로금, 빈도할증위로금)로 구성되며 중복적용이 가능합니다. 

|  | 내차 수리비용 | 보험할증 위로금 | 차량감가 위로금 | 빈도할증 위로금 |
| --- | --- | --- | --- | --- |
| 대인보험 | X | O | X | O  (직전 사고일부터 1년 이내) |
| 대물보험 | X | O | X | O  (직전 사고일부터 1년 이내) |
| 자차보험 | 자기부담금 | O | O | O  (직전 사고일부터 1년 이내) |
| 자차 미처리 | 수리비 | X | O | X |

※ 렌터카인 경우 호스트의 보험료가 할증되지 않으므로, 보험할증위로금과 빈도할증위로금은 발생하지 않습니다. 대신, 보험접수 시 렌터카회사에 납부해야하는 면책금(자기부담금)을 게스트가 부담해야합니다. 

**(1) 보험할증 위로금**

사고점수 1점 당 호스트의 보험료 할증에 대한 보상으로 지급합니다. 

**:  기준보험료(호스트가 실제 보험사에 납부하는 총 연간 보험료) × 10% × 3년**

※ 기준보험료는 대인, 대물, 자차보험 등 실제 가입되어 있는 항목을 모두 더하여 실제 납부하는 총 연간 보험료 입니다.
※ 사고점수는 모든 보험사에서 공통적으로 정하는 기준에 따라 물적할증금액 이하 사고는 0.5점, 초과 사고는 1점에 해당합니다.
※ 사고점수가 1점 미만(0.5점)인 경우 위 공식에서 5%가 적용되며, 1점 초과 시부터는 소수점 자리를 버림처리하여 계산합니다.

**(2) 차량감가 위로금**

사고 1건 당 호스트 차량의 감가상각에 대한 보상으로 지급합니다.

: **보험사고금액(수리비) × 15%**

※ 보험접수 여부와 무관하게 발생합니다. 

**(3) 빈도할증 위로금**

직전 사고일(보험접수한 사고에 한함)부터 1년 이내 사고 발생 시 보험할증 위로금 외 추가로 지급합니다.

**:  기준보험료(호스트가 실제 보험사에 납부하는 총 연간 보험료) × 20% × (365일-이용일수)/365일**

(**예시**)

게스트가 연간보험료 100만원 및 물적할증금액 200만원인 차량을 사용하다가, 처음으로 단독사고가 1건 발생하여 보험사고금액(수리비) 150만원 짜리 자차보험을 접수한 경우: 

- (1) 보험할증 위로금: 15만원 (=100만원 × 5% × 3년)
- (2) 차량감가 위로금: 22만 5천원 (=150만원 × 15%)
- (3) 빈도할증 위로금: 해당없음
- 위로금 합계: 37만 5천 원

※ 자차보험 자기부담금은 게스트가 별도 납부합니다.

---

**사안 별 적용 안내** 

**1. 가해자(상대방)가 있는 사고 발생 시** 

- **게스트 과실이 없는 경우 (과실비율 0)**
    
    ex. 게스트가 주차한 도중에 제3자가 차에 손상을 입힌 경우
    
    보험을 접수할 필요가 없으므로 위로금이 발생하지 않습니다. (가해자 보험을 통해 보상)
    

- **게스트 과실이 있는 경우 (과실비율 0 초과)**
    
    ex. 게스트 운전 중 상대방 차량과 쌍방 과실로 충돌한 경우
    
    (1) **보험으로 처리**: 사안에 따라 대물, 대인, 자차보험을 접수할 수 있습니다.
    
    (2) **보험 접수 없이 현금보상**: 호스트와 협의 하에 보험을 접수하지 않고 현금보상으로 사건을 종결할 수 있습니다. 
    
    (3) **두리카가 정한 방식**: 위 (1), (2)의 방식으로 합의가 되지 않을 경우, 두리카에서 정하는 방식을 따라야 합니다.
    

**2. 가해자(상대방)가 없거나 찾을 수 없는 사고 발생 시** 

- **게스트 과실이 없는 경우 (과실비율 0)**
    
    ex. 게스트가 주차한 도중에 제3자가 차에 손상을 입히고 도주하여 찾을 수 없는 경우
    
    (1) **보험으로 처리**: 자차보험을 접수할 수 있습니다.
    
    (2) **보험 접수 없이 현금보상**: 호스트와 협의 하에 보험을 접수하지 않고 현금보상으로 사건을 종결할 수 있습니다. 
    
    (3) **두리카가 정한 방식**: 위 (1), (2)의 방식으로 합의가 되지 않을 경우, 두리카에서 정하는 방식을 따라야 합니다.
    
    ※ 게스트는 본인의 사용(점유/관리) 도중 발생한 손상에 대하여 과실이 없더라도 이를 원상복구할 책임이 있습니다. 게스트는 원상복구 후 제3자(가해자)를 찾아서 손해배상을 청구(구상)할 수 있습니다.
    

- **게스트 과실이 있는 경우 (과실비율 0 초과)**
    
    ex. 게스트가 주차 도중 실수로 단독 사고를 내는 경우
    
    (1) **보험으로 처리**: 자차보험을 접수할 수 있습니다.
    
    (2) **보험 접수 없이 현금보상**: 호스트와 협의 하에 보험을 접수하지 않고 현금보상으로 사건을 종결할 수 있습니다. 
    
    (3) **두리카가 정한 방식**: 위 (1), (2)의 방식으로 합의가 되지 않을 경우, 두리카에서 정하는 방식을 따라야 합니다.
    

**3. 차량전손 등 중대한 사고 발생 시** 

차량이 전손된 경우에는 보험사에서 지급하는 전손보상액의 20%를 위로금으로 지급합니다. (일반적인 위로금 계산 공식 적용 X)

이외에 사고금액이 큰 중대한 사고의 경우, 사안별로 두리카에서 정하는 방식에 따라 사건을 처리할 수 있습니다.

**4. 호스트 사용 도중 사고 발생 시** 

호스트 사용 도중 차량에 손상이 발생하여 수리가 필요한 경우, 호스트는 게스트가 이로 인하여 차를 이용하지 못하게 된 기간 동안의 월이용료를 환급해주어야 합니다.`}/>
        </TextBox2>
    </>
}

const Wrap = styled.div`
  padding: 32px 20px 100px;

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  h3 {
    margin: 4px 0;
    color: var(--gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
  
  p {
    margin-top: 6px;
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`
const TextBox = styled.div`
  margin-top: 16px;
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  
  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`
const TextBox2 = styled.div`
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  white-space: pre-wrap;

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th, td {
    padding: 4px;
  }
`



export default InsuranceSet;
