import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import {commaNum, openKakaoChat} from "../../toolbox";
import {get, isEmpty, reverse, sortBy} from "lodash";
import {getLoginLink, paymentIdToOrderId, toDateFormat} from "../../toolbox/format";
import {useQuery} from "@apollo/client";
import {GET_PAYMENTS2_BY_USER, GET_PAYMENTS_BY_USER} from "../../query/userQuery";
import {Alert, Snackbar} from "@mui/material";
import {loadTossPayments} from "@tosspayments/payment-sdk";
import {TOSS_CLIENT_KEY} from "../../index";
import queryString from "query-string";
import {flatEntity} from "../../toolbox/query";
import { VehicleSelect } from "./CalendarPage";

const Payment = ({payment, allPayments, allWallets}) => {
    const userInfo = useSelector(({user}) => user.user);

    const needAmount = useMemo(() => {
        let amount = payment.amount;
        let discount = 0;
        if (payment?.walletUses?.length) {
            for (let w of payment.walletUses) {
                discount += w.amount;
            }
        }
        amount += discount;
        return amount;
    }, [payment]);

    const onClickToss = useCallback(async () => {
        const tossPayments = await loadTossPayments(TOSS_CLIENT_KEY);
        await tossPayments.requestPayment('카드', {
            amount: needAmount,
            orderId: paymentIdToOrderId(payment.id),
            orderName: payment.name,
            customerName: userInfo.realname,
            customerEmail: userInfo.email,
            successUrl: window.location.origin + '/payment_success',
            failUrl: window.location.origin + '/payment_fail',
        }).then((value) => {
            console.log(value);
        }).catch((reason => {
            alert(reason);
        }));
    }, [payment, needAmount, userInfo]);

    const statusToString = (status) => {
        if (status === 'READY')
            return '미결제';
        else if (status === 'DONE') {
            return '결제완료';
        }
        else if (status === 'WAITING_FOR_DEPOSIT') {
            return '입금대기';
        }
    }

    const title = useMemo(() => {
        if (payment.__typename === 'PaymentRequest') {
            return `${toDateFormat(payment.sortDate)} 청구`;
        } else if (payment.__typename === 'Wallet') {
            return `${toDateFormat(payment.sortDate)} 월렛 ${payment.amount >= 0 ? '충전' : '차감'}`;
        }
        return '청구';
    }, [payment]);

    const statusString = useMemo(() => {
        if (payment.__typename === 'Wallet') {
            return `월렛 ${payment.amount >= 0 ? '충전' : '차감'}`;
        }
        return statusToString(payment.status);
    }, [payment.status]);

    const body = useMemo(() => {
        if (payment.__typename === 'Wallet') {
            return payment.title;
        }
        return payment.name;
    }, [payment]);

    const remain = useMemo(() => {
        if (!allPayments) return 0;
        const currentIndex = allPayments.findIndex(p => p.id === payment.id);
        if (currentIndex === -1) return 0;
        
        // 현재 payment까지의 모든 거래를 시간순으로 계산합니다
        let balance = 0;
        for (let i = allPayments.length - 1; i >= currentIndex; i--) {
            const p = allPayments[i];
            
            if (p.__typename === 'Wallet') {
                // Wallet type인 경우 amount를 직접 더함
                balance += p.amount;
            } else {
                // PaymentRequest type인 경우 walletUses의 amount를 모두 더함
                if (p.walletUses?.length) {
                    for (let w of p.walletUses) {
                        balance += w.amount;
                    }
                }
            }
        }
        
        return balance;
    }, [payment, allPayments]);

    const price = useMemo(() => {
        if (payment.__typename === 'Wallet') {
            if (payment.amount >= 0) {
                return <>
                    <div className="amount">충전 +{commaNum(payment.amount)}원</div>
                </>
            } else {
                return <>
                    <div className="amount">차감 {commaNum(payment.amount)}원</div>
                </>
            }
        }

        let amount = payment.amount;
        let discount = 0;
        if (payment?.walletUses?.length) {
            for (let w of payment.walletUses) {
                discount += w.amount;
            }
        }
        amount += discount;
        if (discount) {
            return <>
                <div className="amount">청구 금액 : {commaNum(payment.amount)}원</div>
                <div className="amount">월렛 차감 : {commaNum(discount)}원</div>
                <div className="amount">월렛 잔액 : {commaNum(remain)}원</div>
                <div className="amount">결제 필요 금액 : {commaNum(amount)}원</div>
            </>
        }
        return <div className="amount">{commaNum(payment.amount)}원</div>
    }, [payment, remain]);

    const status = useMemo(() => {
        if (payment.__typename === 'Wallet') {
            return payment.amount >= 0 ? 'DONE' : 'READY';
        }
        return payment.status;
    }, [payment]);

    return <_Payment status={status}>
        <div className="detail">
            <div className="wrap">
                <div className="date">
                    <span>{title}</span>
                    <span className={status}>{statusString}</span>
                </div>
                <div className="content">
                    <div className="title">{body}</div>
                    <div className="description">{payment.description}</div>
                </div>
                {price}
                {payment.__typename === 'Wallet' && <div className="amount">월렛 잔액 {commaNum(remain)}원</div>}
            </div>
        </div>
        <div className="btn">
            {payment.status === 'DONE' ? <>
            {payment.toss_payment_histories.length > 0 && payment.toss_payment_histories[0].receiptUrl &&
                <div onClick={() => window.open(payment.toss_payment_histories[0].receiptUrl, '_blank')}>매출전표</div>}
                <div onClick={() => {
                    openKakaoChat('payment_EE_open_chat', payment.id);
                    // openChannelTalk('payment_EE_open_chat', payment.id, `${payment.name} (결제ID: ${payment.id}) 결제건에 대해 문의가 있어요!`)
                }}>문의하기</div>
            </>: payment.status === 'READY' ? <>
                <div className="pay" onClick={onClickToss}>결제하기</div>
            </>: <>
                <div onClick={() => {
                    openKakaoChat('payment_EE_open_chat', payment.id);
                    // openChannelTalk('payment_EE_open_chat', payment.id, `${payment.name} (결제ID: ${payment.id}) 결제건에 대해 문의가 있어요!`)
                }}>문의하기</div>
            </>}
        </div>
    </_Payment>
}

export const Payment2Page = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const [contracts, setContracts] = useState(null);
    const [selectedContract, setSelectedContract] = useState(null);

    const payments = useMemo(() => {
        if (!selectedContract) return null;
        const visibleWallets = selectedContract.wallets.filter(w => {
            // if (w.paymentCharge && w.paymentCharge.id === selectedContract?.id) {
            //     return false;
            // }
            if (w?.paymentUse?.contract?.id && w.paymentUse.contract.id === selectedContract?.id) {
                return false;
            }
            return true;
        });
        const result = [
            ...selectedContract.payment_requests.map(item => ({
                ...item,
                sortDate: item.startAt || item.createdAt
            })),
            ...visibleWallets.map(item => ({
                ...item,
                sortDate: item.recordedAt || item.createdAt
            })),
        ].sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate));
        return result;
    }, [selectedContract]);

    useEffect(() => {
        if (!parsed?.id) {
            if (isEmpty(tokenInfo)) {
                const to = getLoginLink(location);
                navigate(to);
            } else {
                window.history.replaceState(null, null, '?id=' + tokenInfo.id);
            }
        }
    }, [tokenInfo, parsed]);

    useQuery(GET_PAYMENTS2_BY_USER, {
        skip: !parsed?.id && !tokenInfo?.id,
        variables: {
            id: parsed?.id ?? tokenInfo?.id,
        },
        onCompleted: data => {
            let contracts = get(data, "usersPermissionsUser.data.attributes.contracts.data", []);
            contracts = contracts.map(c => flatEntity(c));
            contracts = contracts.filter(c => c.vehicle && !c.vehicle.draftVehicle);

            for (let c of contracts) {
                let remain = 0;
                for (let w of c.wallets) {
                    remain += w.amount;
                }
                c.remainWalletAmount = remain;
            }

            if (contracts.length === 1) {
                setSelectedContract(contracts[0]);
            }
            setContracts(contracts);
            // const payments = get(data, "paymentRequests.data", []);
            // setPayments(reverse(sortBy(payments.map(p => flatEntity(p)), (p) => {
            //     return p.status +  (p.startAt || p.createdAt);
            // })));
        }
    });

    return <_PaymentPage>
        <VehicleSelect visible={contracts?.length > 1}>
            {contracts?.map((c) => (
                <div
                    key={c.id}
                    className={
                        selectedContract?.id === c.id
                            ? "selected-vehicle"
                            : "unselected-vehicle"
                    }
                    onClick={() => {
                        setSelectedContract(c);
                    }}
                >
                    {c.vehicle.model} ({c.vehicle.numberPlate})
                </div>
            ))}
        </VehicleSelect>
        <div className="remain">
            현재 월렛 잔액 : {commaNum(selectedContract?.remainWalletAmount ?? 0)}원<br/>
            출금 제한 금액 : {commaNum(selectedContract?.minWallet ?? 0)}원<br/>
            정산 예정 금액 : {commaNum(Math.max(0, (selectedContract?.remainWalletAmount ?? 0) - (selectedContract?.minWallet ?? 0)))}원
        </div>
        <h2>결제 목록</h2>
        <Payments>
            {payments && payments.map((p) =>
                <Payment payment={p} allPayments={payments} allWallets={selectedContract?.wallets} />
            )}

            {payments && payments.length === 0 && <div>
                결제 항목이 없습니다.
            </div>}
        </Payments>
    </_PaymentPage>
}

const _PaymentPage = styled.div`
  font-family: 'Pretendard',sans-serif;
  padding: 10px;

  > h2 {
    padding: 16px;
  }

  > .remain {
    padding: 16px;
    font-size: 16px;
    font-weight: 300;
    line-height: 120%;
  }
`
const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
`

const Payments = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  background-color: #F5F5F5;
`

const _Payment = styled.div`
  border-radius: 8px;
  background-color: white;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #DDDDDD;
  > .detail {
    padding: 12px;
    > .wrap {
      > .date {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        padding-left: 2px;
        padding-right: 4px;
        > .DONE {
          color: #008C00;
        }
      }

      > .content {
        font-size: 14px;
        margin: 12px 0 10px;
        padding: 8px 10px;
        background-color: #EEEEEE;
        border-radius: 8px;
        white-space: pre-wrap;
        word-break: keep-all;
        font-weight: 500;
        line-height: 18px;
        > .description {
          font-size: 12px;
          margin-top: 8px;
          font-weight: 300;
        }
      }

      > .amount {
        text-align: right;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
      }
    }
  }

  > .btn {
    display: flex;
    flex-grow: 1;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    border-top: inherit;
    > div {
      cursor: pointer;
      color: #202020;
      flex-grow: 1;
      flex-basis: 80px;
      text-align: center;
      border-left: 1px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
      :first-child {
        border-left: none;
        border-right: none;
      }
      :last-child {
        border-right: none;
      }
    }

    > .pay {
      font-size: 16px;
      font-weight: 600;
      color: #007aff;
    }
  }
`;
