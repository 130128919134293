import styled, {css} from "styled-components";
import {filter, find, get, isEmpty} from "lodash";
import {GET_USER, MY_CONTRACTS, UPDATE_USER} from "../../query/userQuery";
import {useMutation, useQuery} from "@apollo/client";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {Dialog, Popover} from "@mui/material";
import {asyncSendTelegram, openKakaoChat, useForm} from "../../toolbox";
import {useDispatch, useSelector} from "react-redux";
import {loadingAction} from "../../redux/loadingReducer";
import {authAction} from "../../redux/authReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import HelpIcon from "@mui/icons-material/Help";
import {VehicleThumbnail} from "../vehicle/component/VehicleThumbnail";
import VehicleLocationSection from "../vehicle/component/VehicleLocationSection";
import {MB8} from "../../toolbox/UtilComponent";
import {flatEntity} from "../../toolbox/query";
import moment from "moment-timezone";
import {calcAge, calcTimeDiff, convertDooricar, getLoginLink} from "../../toolbox/format";
import {GUEST_WANTED} from "../../toolbox/guestTag";
import { isAvailableContract } from "../../toolbox/calculate";
import { useMemo } from "react";
import { useCallback } from "react";
import { TinyVehicle } from "../vehicle/component/ListVehicle2";
import qs from "query-string";

export const GuestWelcomePage = () => {
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const userId = parsed?.id;
    const {data: myContracts} = useQuery(MY_CONTRACTS, {
        variables: {
            id: tokenInfo?.id,
        },
        skip: !tokenInfo,
        fetchPolicy: "cache-first",
    });

    const [myVehicles, setMyVehicles] = useState(null);
    const [requestOpen, setRequestOpen] = useState(false);

    useEffect(() => {
      if (isEmpty(tokenInfo)) {
          const to = getLoginLink(location);
          navigate(to);
      }
    }, []);

    useEffect(() => {
        if (myContracts) {
            let contracts = get(myContracts, "usersPermissionsUser.data.attributes.contracts.data", null);
            contracts = contracts.map(c => flatEntity(c));
            contracts = contracts.filter((c) => isAvailableContract(c) && c.vehicle.doorive && c.contractType === 'DOORICAR_WAITING');
            let vehicles = contracts.map(c => convertDooricar(c.vehicle, false, true));
            setMyVehicles(vehicles);
        }
    }, [myContracts]);

    useEffect(() => {
        if (myVehicles) {
            if (myVehicles.length === 0) {
              alert('호스트만 환영 인사가 가능합니다. 먼저 차량을 등록해주세요.');
              navigate('/vehicle/write');
            } else {
              let visibleVehicles = myVehicles.filter(v => v.visible);
              if (visibleVehicles.length === 0) {
                alert('환영 인사를 보내기전에 차량의 비활성화를 해제해주세요.');
                setRequestOpen(true);
              } else if (visibleVehicles.length > 0) {
                handleSendWelcome(visibleVehicles[0].hostContract.id).then(() => {});
              }
            }
        }
    }, [myVehicles]);

    const handleSendWelcome = useCallback(async (contractId) => {
      dispatch(loadingAction.loading(true));
      console.log('handleSendWelcome', contractId, userId);
      const res = await axios.post(SERVER_ADDRESS + '/api/cars/sendWelcome', {
        contractId: contractId,
        targetId: userId,
      });
      alert('환영 인사를 보냈습니다.');
      dispatch(loadingAction.loading(false));
      
      if (res?.data?.roomId) {
        navigate(`/my/chat/${res.data.roomId}`);
        return;
      }
    }, [dispatch, navigate, userId]);

    return <_MyDooricarPage>
        <MyVehicleSelectDialog open={requestOpen} onClose={async (selected) => {
            setRequestOpen(false);
            if (selected) {
              dispatch(loadingAction.loading(true));
              const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/setVisible', {
                contractId: selected.hostContract.id,
                visible: true,
              });
              await handleSendWelcome(selected.hostContract.id);
            }
        }} myVehicles={myVehicles} />
    </_MyDooricarPage>;
}

const _MyVehicleSelectDialog = ({className, open, onClose, myVehicles, title}) => {
    const [selected, setSelected] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (myVehicles && myVehicles.length !== 0) {
            setSelected(myVehicles[0]);
        }
    }, [myVehicles]);

    return <Dialog open={open} onClose={() => onClose(null)}>
        <div className={className}>
            <h3>{title ?? '차량 선택'}</h3>
            <div className="vehicles">
                {myVehicles && myVehicles.length === 0 && <div style={{padding: '10px 22px', textAlign: 'center'}}>
                    차량이 없습니다. 차량을 등록해주세요.
                    <div className="btn" onClick={() => navigate('/vehicle/write')}>차량 등록하러가기</div>
                </div>}
                {myVehicles && myVehicles.map(v => (
                    <div key={v.id} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <input 
                            type="radio" 
                            id={`vehicle-${v.id}`} 
                            name="vehicle" 
                            value={v.id} 
                            checked={selected && selected.id === v.id}
                            onChange={() => setSelected(v)}
                            style={{ marginRight: '10px' }}
                        />
                        <label htmlFor={`vehicle-${v.id}`} style={{ flexGrow: 1 }}>
                            <TinyVehicle 
                                vehicle={v} 
                                imageHide={false} 
                                hideManner={true}
                            />
                        </label>
                    </div>
                ))}
            </div>
            {myVehicles && myVehicles.length > 0 && <div className="btn-box">
                <div className="btn" onClick={() => onClose(selected)}>확인</div>
            </div>}
        </div>
    </Dialog>
}

export const MyVehicleSelectDialog = styled(_MyVehicleSelectDialog)`
    padding: 12px;
    .vehicles {
      .btn {
        display: flex;
        justify-content: center;
        margin: 10px 0;
        background-color: #33CCC1;
        color: white;
        padding: 12px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
    .btn-box {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
        gap: 12px;
        .btn {
            flex-grow: 1;
            text-align: center;
            background-color: #33CCC1;
            color: white;
            padding: 12px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
`

const _MyDooricarPage = styled.div`
  .section {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 24px 32px;
    > .title {
      display: flex;
      //padding-left: 32px;
      //padding-right: 32px;
      line-height: 18px;
      flex-wrap: wrap;
      font-weight: 700;
      font-size: 14px;
      align-items: center;
      gap: 4px;
      > .right_button {
        cursor: pointer;
        font-size: 12px;
        color: #6993FF;
        text-decoration-line: underline;
        text-underline-offset: 2px;
        text-decoration-color: #6993FF;
        text-decoration-thickness: auto;
        text-decoration-style: solid;
      }
    }

    > .action-btn {
      cursor: pointer;
      flex-grow: 1;
      box-sizing: border-box;
      background-color: #33CCC1;
      border-radius: 4px;
      margin-top: 6px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 18px;
      width: 180px;
      padding: 6px;
      color: white;
      text-align: center;
    }
  }

  .section2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 0 24px;
    > .pad32 {
      padding-left: 32px;
      padding-right: 32px;
    }
    > .title {
      display: flex;
      padding-left: 32px;
      padding-right: 32px;
      line-height: 18px;
      flex-wrap: wrap;
      font-weight: 700;
      font-size: 14px;
      align-items: center;
      gap: 4px;
    }
  }

  .items {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  
  .location {
    margin: 0 0 18px;
  }
  
  .vehicles {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 24px;
    
    > .vehicle {
      box-sizing: border-box;
      max-width: calc(50% - 8px);
      flex-basis: 140px;
      flex-grow: 1;
      height: 120px;
      margin: 4px 4px 36px 4px;
      text-align: center;
      cursor: pointer;
      position: relative;
    }
  }
`