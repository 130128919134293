import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {loadingAction} from "../../redux/loadingReducer";
import {commaNum, openKakaoChat} from "../../toolbox";
import {filter, get, find, reverse, sortBy, sumBy, groupBy, flatten, sum, maxBy, max} from "lodash";
import {filterAvailableWallets, getLoginLink, paymentIdToOrderId, toDateFormat} from "../../toolbox/format";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_WALLET, GET_PAYMENTS_BY_CONTRACT, GET_PAYMENTS_BY_USER, GET_WALLETS_BY_CONTRACT} from "../../query/userQuery";
import {Alert, Dialog, Snackbar} from "@mui/material";
import {loadTossPayments} from "@tosspayments/payment-sdk";
import {SERVER_ADDRESS, TOSS_CLIENT_KEY} from "../../index";
import queryString from "query-string";
import {flatEntity} from "../../toolbox/query";
import {GET_ALL_CONTRACTS_CALENDAR} from "../../query/calendarQuery";
import moment from "moment-timezone";
import {isAdmin} from "../../toolbox/logic";
import {isShowContractWallet} from "../../toolbox/calendar";
import {ContractSelect} from "../my/WalletPage";
import axios from "axios";
import "moment/locale/ko";
import { PositiveBtn } from "../vehicle/VehicleAdd2/BottomNavigator";
import { UPDATE_CONTRACT } from "../../query/vehicleQuery";

const getStartAt = (v) => {
    return (v.recordedAt ?? v.startAt)? (v.recordedAt ?? v.startAt): v.createdAt;
}

const Contract = ({v, c}) => {
    const [collapse, setCollapse] = useState(false);

    const combineWalletPayment = (wallets, payments) => {
        let _wallets = wallets.filter(w => w.amount >= 0);
        let result = _wallets.concat(payments);
        result = sortBy(result, (v) => getStartAt(v)).reverse();
        return result;
    }

    const sumIncome = (wallets) => {
        let _wallets = wallets.filter(w => w.amount >= 0 && w.contract.id !== w?.paymentCharge?.contract?.id);
        return sumBy(_wallets, w => w.amount);
    }

    const sumPayment = (payments) => {
        return sumBy(payments, p => p.amount - sumBy(p.walletCharges.filter(w => w.contract.id === p.contract.id), w => w.amount));
    }

    const sumGive = (payments) => {
        return sumBy(payments, p => sumBy(p.walletCharges.filter(w => w.contract.id !== p.contract.id && w.amount >= 0), w => w.amount));
    }

    const sumUnpaid = (payments) => {
        return sumBy(payments.filter(p => p.status === 'WAITING_FOR_DEPOSIT'), p => p.amount);
    }

    return <div style={{marginTop: '20px'}}>
        <div>{v.numberPlate} ({c.startDate} ~ {c.endDate})</div>
        <div>{c.users_permissions_user.realname} {c.contractType === 'DOORICAR'? '게스트': '호스트'}</div>
        <div>총 수익 : {commaNum(sumIncome(c.wallets))}</div>
        <div>총 청구 : {commaNum(sumPayment(c.payment_requests))}</div>
        <div>총 상대 수익 : {commaNum(sumGive(c.payment_requests))}</div>
        <div>총 미수금 : {commaNum(sumUnpaid(c.payment_requests))}</div>
        <div onClick={() => setCollapse(!collapse)}>{collapse? '닫기': '열기'}</div>
        {collapse && combineWalletPayment(c.wallets, c.payment_requests).map((v) => v.name? <Payment payment={v} />: <Wallet wallet={v} />)}
    </div>
}
const VehicleSummary = ({matchedVehicles}) => {
    return <div>
        {matchedVehicles && <div>
            <div>합계</div>
            {matchedVehicles.map(v => v.contracts.map(c => {
                return <Contract v={v} c={c} />
            }))}
            <div></div>
            <br/>
        </div>}
    </div>
}

const LikeGowid = styled.div`
  display: flex;
  height: 70px;
  white-space: pre-wrap;
  border-spacing: 0px;
  border-collapse: collapse;
  border-bottom: 1px solid rgb(240, 243, 245);
  background: rgb(255, 255, 255);
  align-items: center;
  justify-content: space-between;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
`

const CardUseSummary = ({allWallets}) => {
    const filterCard = (wallets) => {
        return sortBy(wallets.filter(w => w.title === '두리케어카드 사용' && w.createdAt >= moment.tz('Asia/Seoul').subtract(2, 'months').format('YYYY-MM-DD')), w => w.createdAt).reverse();
    }
    const formatText = ({description, amount}) => {
        const splitted = description.split('\n');
        const created = moment.tz(splitted[1].split(': ', 2)[1], 'YYYY-MM-DD HH:mm:ss',  'Asia/Seoul');
        const str = `${created.format('MM.DD. (dd)')}\n${created.format('HH:mm')}`
        const numberPlate = splitted[0].split(': ', 2)[1];
        return <>
            <div style={{width: '72px', textAlign: 'center'}}>{str}</div>
            <div>{numberPlate}</div>
            {/*<div>{splitted[2].split(': ', 2)[1]}</div>*/}
            <div style={{width: '80px'}}>{commaNum(amount)}원</div>
        </>
        return `${created}\t${splitted[0].split(': ', 2)[1]}\t${commaNum(amount)}원`;
        // return `${splitted[0].split(': ', 2)[1]}\n${splitted[1].split(': ', 2)[1]}\n${splitted[2].split(': ', 2)[1]}\n${commaNum(amount)}원`;
    }
    return <div>
        {allWallets && <div>
            {filterCard(allWallets).map(w => <LikeGowid>
                {formatText(w)}
            </LikeGowid>)}
            <br/>
        </div>}
    </div>
}

const RecentPaymentSummary = ({allPayments}) => {

    const groupByContract = (payments) => {
        let group = groupBy(payments, (p) => p.contract.id);
        let result = [];
        for (let contractId in group) {
            const v = group[contractId];
            result.push({
                numberPlate: v[0].contract.vehicle.numberPlate,
                realname: v[0].contract.users_permissions_user.realname,
                payments: v,
                ...v[0].contract,
            });
        }
        return result;
    }

    const filterRecent = (payments) => {
        return payments.filter(p => p.status === 'WAITING_FOR_DEPOSIT' || getStartAt(p) >= moment.tz('Asia/Seoul').subtract(1, 'month').format('YYYY-MM-DD'));
    }

    const unpaidSum = (payments) => {
        return sumBy(payments.filter(p => p.status === 'WAITING_FOR_DEPOSIT'), p => getPaymentAmount(p));
    }

    const getPaymentAmount = (payment) => {
        const totalAmount = payment.amount;
        const discountAmount = sumBy(payment.walletUses, (w) => w.amount);
        const paymentAmount = totalAmount + discountAmount;
        return paymentAmount;
    }

    return <div>
        {allPayments && <div>
            <div>합계</div>
            <div>최근 청구금액 : {commaNum(walletSum(filterRecent(allPayments)))}원</div>
            <div>최근 결제완료 : {commaNum(walletSum(filterRecent(allPayments).filter(p => p.status === 'DONE')))}원</div>
            <div>현재 미수금 : {commaNum(unpaidSum(allPayments))}원</div>
            {groupByContract(filterRecent(allPayments)).map(c => <div style={{margin: '24px 4px'}}>
                <div>
                    {c.realname} {c.contractType === 'DOORICAR'? '게스트': '호스트'} ({c.numberPlate}) - {c.startDate} ~ {c.endDate}
                </div>
                <div>
                    {sortBy(c.payments, p => getStartAt(p)).reverse().map(p => <div style={{padding: '4px', margin: '6px 0', color: 'white', background: p.status === 'DONE'? 'green': 'red'}}>
                        {moment.tz(getStartAt(p), 'Asia/seoul').format('YYYY-MM-DD')} - {p.name} {commaNum(getPaymentAmount(p))}원<br/>
                    </div>)}
                </div>
            </div>)}
            <div></div>
            <br/>
        </div>}
    </div>
}

const UnpaidPaymentSummary = ({allPayments}) => {

    const filterNotDone = (payments) => {
        return payments.filter(p => p.status === 'WAITING_FOR_DEPOSIT');
    }

    const groupNotDone = (payments) => {
        let group = groupBy(payments, (p) => p.contract.id);
        let result = [];
        for (let contractId in group) {
            const v = group[contractId];
            result.push({
                phone: v[0].contract.users_permissions_user.phone,
                numberPlate: v[0].contract.vehicle.numberPlate,
                realname: v[0].contract.users_permissions_user.realname,
                payments: v,
            });
        }
        return result;
    }

    const calcAdded = (payment) => {
        const start = moment.tz(getStartAt(payment), 'Asia/seoul').format('YYYY-MM-DD');
        const now = moment.tz('Asia/Seoul');

        let diff = now.diff(start);
        let duration = moment.duration(diff);
        let overdue = Math.floor(duration.asDays());

        // return {day: overdue, price: Math.round(overdue * 10000 + (payment.amount * (0.2 / 365 * overdue)))}
        return {day: overdue, price: Math.round(payment.amount * (0.2 / 365 * overdue))}
    }

    const getPaymentAmount = (payment) => {
        const totalAmount = payment.amount;
        const discountAmount = sumBy(payment.walletUses, (w) => w.amount);
        const paymentAmount = totalAmount + discountAmount;
        return paymentAmount;
    }

    return <div>
        {allPayments && <div>
            <div>합계</div>
            <div>전체 미수금 (원금만) : {commaNum(walletSum(filterNotDone(allPayments)))}원</div>
            {groupNotDone(filterNotDone(allPayments)).map(c => <div style={{margin: '24px 4px'}}>
                <div>
                    {c.realname} ({c.numberPlate}) - 합계(원금만) : {commaNum(sumBy(c.payments, p => getPaymentAmount(p)))}원<br/>
                    {c.phone}
                </div>
                <div>
                    {sortBy(c.payments, p => getStartAt(p)).reverse().map(p => <div style={{margin: '6px 0'}}>
                        {moment.tz(getStartAt(p), 'Asia/seoul').format('YYYY-MM-DD')} - {p.name} {commaNum(getPaymentAmount(p))}원<br/>
                        연체일수 : {calcAdded(p).day}일, 가산금 : {commaNum(calcAdded(p).price)}원
                    </div>)}
                </div>
            </div>)}
            <div></div>
            <br/>
        </div>}
    </div>
}

const WalletSummary = ({allWallets, allPayments}) => {
    const [createWallet] = useMutation(CREATE_WALLET);
    const [updateContract] = useMutation(UPDATE_CONTRACT);
    const dispatch = useDispatch();
    const contractFilter = useCallback((ws) => {
        const cut = moment.tz('Asia/Seoul').subtract(2, 'months').format('YYYY-MM-DD');
        if (ws.contractType === 'DOORICAR') {
            return !(walletSum(ws.wallets) === 0 && ws.endDate < cut);
        }
        const maxRecorded = max(ws.wallets.map((w) => w.recordedAt ? w.recordedAt: w.createdAt));
        return !(walletSum(ws.wallets) === 0 && moment.tz(maxRecorded, 'Asia/Seoul').format('YYYY-MM-DD') < cut);
    }, [allPayments]);

    const getPaymentAmount = useCallback((payment) => {
        const totalAmount = payment.amount;
        const discountAmount = sumBy(payment.walletUses, (w) => w.amount);
        const paymentAmount = totalAmount + discountAmount;
        return paymentAmount;
    }, []);

    const unpaidPayments = useCallback((contractId) => {
        return allPayments.filter(p => p.status === 'WAITING_FOR_DEPOSIT' && String(p?.contract?.id) === String(contractId));
    }, [allPayments]);

    const unpaidSum = useCallback((contractId) => {
        return sumBy(unpaidPayments(contractId), p => getPaymentAmount(p));
    }, [unpaidPayments, getPaymentAmount]);

    const handleMinWallet = useCallback((contractId) => {
        if (window.confirm('출금 불가 포인트를 해제하시겠습니까?')) {
            dispatch(loadingAction.loading(true));
            updateContract({variables: {id: contractId, data: {minWallet: 0}}}).then((res) => {
                console.log(res);
                dispatch(loadingAction.loading(false));
            });
        }
    }, [updateContract]);

    const handleWalletExecute = useCallback((contractId) => {
        if (window.confirm('월렛으로 미납금을 정리하시겠습니까?')) {
            const paymentIds = unpaidPayments(contractId).map(p => p.id);
            dispatch(loadingAction.loading(true));
            axios.post(SERVER_ADDRESS + '/api/credit-cards/walletExecute', {paymentIds}).then((res) => {
                console.log(res);
                dispatch(loadingAction.loading(false));
            });
        }
    }, [unpaidPayments]);

    const handleWalletWithdraw = useCallback(async (ws) => {
        let amount = window.prompt('출금할 금액을 숫자로 입력하세요.', getWithdrawAmount(ws));
        if (amount) {
            amount = Number(amount);
            const wallet = await createWallet({
                variables: {
                    data: {
                        title: "수익금 출금",
                        amount: -amount,
                        user: ws.userId,
                        memo: '어드민페이지에서 생성됨',
                        contract: ws.id,
                    },
                },
                onCompleted: data => {
                }
            });
            const createdId = wallet?.data?.createWallet?.data?.id;
            window.prompt(`"수익금 출금" 으로 생성되었습니다. 제목 또는 내용 수정은 아래 strapi 링크에서 해주세요.`,
                `https://dry-river-37620.herokuapp.com/admin/content-manager/collection-types/api::wallet.wallet/${createdId}`);
        } else {
        }
    }, [createWallet]);

    const getWithdrawAmount = useCallback((ws) => {
        return Math.max(0, walletNow(ws.wallets) - (ws.minWallet ?? 0));
    }, [allWallets]);

    const getEndDate = useCallback((ws) => {
        if (ws.contractType === 'DOORICAR') {
            return ws.endDate;
        }
        console.log(ws);
        const dooricarContracts = ws.contracts.filter(c => c.contractType === 'DOORICAR');
        if (dooricarContracts.length === 0) return null;
        const endDates = dooricarContracts.map(c => c.endDate);
        if (endDates.includes(null)) return null;
        return max(endDates);
    }, [allWallets]);
    
    return <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        {allWallets && <div style={{lineHeight: '130%', display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <div>합계</div>
            <div>전체 월렛 포인트 : {commaNum(walletSum(flatten(allWallets.map(ws => ws.wallets))))}</div>
            <div>충전 예정 포인트 : {commaNum(walletAfter(flatten(allWallets.map(ws => ws.wallets))))}</div>
            <div>현재 월렛 포인트 : {commaNum(walletNow(flatten(allWallets.map(ws => ws.wallets))))}</div>
            <div>출금 가능 포인트 : {commaNum(sumBy(allWallets, ws => getWithdrawAmount(ws)))}</div>
            <br/>
        </div>}
            {allWallets && sortBy(filter(allWallets, (ws) => contractFilter(ws)), (ws) => getWithdrawAmount(ws)).reverse().map((ws) => <div style={{lineHeight: '130%', display: 'flex', flexDirection: 'column', gap: '8px', border: '1px solid #DDDDDD', borderRadius: '8px', padding: '16px'}}>
            <div>
                <div>{ws.realname}</div>
                <div>{ws.numberPlate} {ws.contractType === 'DOORICAR'? '게스트': '호스트'} {getEndDate(ws) && <span>(계약 종료 : {getEndDate(ws)})</span>}</div>
                <div>{ws.phone}</div>
                <div>{ws.accountNumber}</div>
            </div>
            <div>
                <div>전체 월렛 포인트 : {commaNum(walletSum(ws.wallets))}</div>
                <div>출금 불가 포인트 : {commaNum(ws.minWallet ?? 0)}</div>
                <div>충전 예정 포인트 : {commaNum(walletAfter(ws.wallets))}</div>
                <div>현재 월렛 포인트 : {commaNum(walletNow(ws.wallets))}</div>
                <div>출금 가능 포인트 : {commaNum(getWithdrawAmount(ws))}</div>
                <div>미납 금액 : {commaNum(unpaidSum(ws.id))}</div>
            </div>
            {!!ws.minWallet && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <PositiveBtn onClick={() => handleMinWallet(ws.id)}>출금 불가 포인트 해제</PositiveBtn>
            </div>}
            {getWithdrawAmount(ws) > 0 && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <PositiveBtn onClick={() => handleWalletWithdraw(ws)}>출금하기</PositiveBtn>
            </div>}
            {unpaidSum(ws.id) > 0 && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <PositiveBtn onClick={() => handleWalletExecute(ws.id)}>월렛으로 미납금 정리</PositiveBtn>
            </div>}
            {/*{ws.wallets.map((w) => <div>{w.amount}</div>)}*/}
        </div>)}
    </div>
}

export const PaymentManagePage2 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [matchedVehicles, setMatchedVehicles] = useState(null);
    const [allWallets, setAllWallets] = useState(null);
    const [allPayments, setAllPayments] = useState(null);
    const [allWalletDatas, setAllWalletDatas] = useState(null);


    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/wallets/getAdminData', {}).then((res) => {
            setMatchedVehicles(res.data.matchedVehicles);

            const contracts = groupBy(res.data.allWallets, (w) => w.contract.id);
            const result = [];
            for (let contractId in contracts) {
                const v = contracts[contractId];
                result.push({
                    id: contractId,
                    contractType: v[0].contract.contractType,
                    minWallet: v[0].contract.minWallet,
                    endDate: v[0].contract.endDate,
                    realname: v[0].user.realname,
                    phone: v[0].user.phone,
                    accountNumber: v[0].user.accountNumber,
                    numberPlate: v[0].contract.vehicle.numberPlate,
                    wallets: v,
                    contracts: v[0].contract.vehicle.contracts,
                    userId: v[0].user.id,
                });
            }
            setAllWallets(result);
            setAllPayments(res.data.allPayments);
            setAllWalletDatas(res.data.allWallets);
        });
    }, []);

    useEffect(() => {
        if (!isAdmin(tokenInfo?.id)) {
            navigate('/');
        }
    }, []);
    return <_PaymentPage>
        {/* <h2>미수금 현황</h2>
        <UnpaidPaymentSummary allPayments={allPayments} /> */}
        <h2>최근 청구</h2>
        <RecentPaymentSummary allPayments={allPayments} />
        <h2>월렛 현황</h2>
        <WalletSummary allWallets={allWallets} allPayments={allPayments} />
        <h2>카드 사용 현황</h2>
        <CardUseSummary allWallets={allWalletDatas} />
        <h2>차량 현황</h2>
        <VehicleSummary matchedVehicles={matchedVehicles} />
        {/*{matchedVehicles && matchedVehicles.map(v => <div>*/}
        {/*    <div>{v.numberPlate}</div>*/}
        {/*    <div></div>*/}
        {/*</div>)}*/}
    </_PaymentPage>
}

const Wallet = ({wallet}) => {
    const walletStatus = () => {
        return wallet.amount > 0? 'DONE': 'READY';
    }

    const err = !(wallet.paymentCharge) && wallet.amount > 0;

    return <_Payment>
        <div className="detail">
            <div className="wrap">
                <div className="date">
                    <span>{toDateFormat(wallet.recordedAt || wallet.createdAt)} 내역</span>
                    <span className={walletStatus()}>{wallet.amount > 0? '월렛충전': '월렛차감'}{toDateFormat(wallet.recordedAt || wallet.createdAt) > toDateFormat()? ' 예정': ''}</span>
                </div>
                <div className="content">
                    <div className="title">{wallet.title}</div>
                    <div className="description">{wallet.description}</div>
                </div>
                {err? <div className="error">이상충전건</div>: null}
                <div className="amount">{commaNum(wallet.amount)}원</div>
            </div>
        </div>
    </_Payment>
}

const Payment = ({payment}) => {
    const statusToString = (status) => {
        if (status === 'READY')
            return '미결제';
        else if (status === 'DONE') {
            return '결제완료';
        }
        else if (status === 'WAITING_FOR_DEPOSIT') {
            return '입금대기';
        }
    }

    const totalAmount = payment.amount;
    const discountAmount = sumBy(payment.walletUses, (w) => w.amount);
    const paymentAmount = totalAmount + discountAmount;

    const selfCharges = filter(payment.walletCharges, (w) => w.contract.id === payment.contract.id);
    const selfChargeAmount = sumBy(selfCharges, (w) => w.amount);
    const opponents = filter(payment.walletCharges, (w) => w.contract.id !== payment.contract.id);
    const opponentChargeAmount = sumBy(opponents, (w) => w.amount);
    const chargeAmount = sumBy(payment.walletCharges, (w) => w.amount);
    const err = !(payment.name.includes('보증') || payment.name.includes('하이패스')) && payment.walletCharges.length === 0 && payment.amount !== 0;

    return <_Payment>
        <div className="detail">
            <div className="wrap">
                <div className="date">
                    <span>{toDateFormat(payment.startAt || payment.createdAt)} 청구</span>
                    <span className={payment.status}>{statusToString(payment.status)}</span>
                </div>
                <div className="content">
                    <div className="title">{payment.name}</div>
                    <div className="description">{payment.description}</div>
                </div>
                {err? <div className="error">이상결제건</div>: null}
                <div className="amount">결제 ID : {payment.id}</div>
                <div className="amount">청구 금액 : {commaNum(payment.amount)}원</div>
                <div className="amount">할인 금액 : {commaNum(discountAmount)}원</div>
                <div className="amount">실결제 금액 : {commaNum(paymentAmount)}원</div>
                {selfCharges.length ? <div className="amount">자기 충전 : {commaNum(selfChargeAmount)}원 ({selfCharges.map(w => w.user.id).join(',')})</div> : null}
                {opponents.length ? <div className="amount">상대 충전 : {commaNum(opponentChargeAmount)}원 ({opponents.map(w => w.user.id).join(',')})</div> : null}
                <div className="amount">두리카 매출 : {commaNum(totalAmount - chargeAmount)}원</div>
            </div>
        </div>
        <div>
        </div>
    </_Payment>
}

const _PaymentPage = styled.div`
  font-family: 'Pretendard',sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 120%;

  > h2 {
    padding: 16px;
  }
`

const walletSum = (ws) => {
    return ws.reduce((sum, w) => sum + w.amount, 0);
}

const walletNow = (ws) => {
    const isAfter = (v) => {
        const now = moment.tz('Asia/Seoul');
        const writed = v.recordedAt ?? v.startAt;
        const created = moment.tz(writed? writed: v.createdAt, 'Asia/Seoul');
        return now.isAfter(created);
    }
    return walletSum(filter(ws, (w) => isAfter(w)));
}

const walletAfter = (ws) => {
    const isAfter = (v) => {
        const now = moment.tz('Asia/Seoul');
        const writed = v.recordedAt ?? v.startAt;
        const created = moment.tz(writed? writed: v.createdAt, 'Asia/Seoul');
        return !now.isAfter(created);
    }
    return walletSum(filter(ws, (w) => isAfter(w)));
}

const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
`

const Payments = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  background-color: #F5F5F5;
`

const _Payment = styled.div`
  border-radius: 8px;
  background-color: white;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #DDDDDD;
  > .detail {
    padding: 12px;
    > .wrap {
      > .date {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        padding-left: 2px;
        padding-right: 4px;
        > .DONE {
          color: #008C00;
        }
      }

      > .content {
        font-size: 14px;
        margin: 12px 0 10px;
        padding: 8px 10px;
        background-color: #EEEEEE;
        border-radius: 8px;
        white-space: pre-wrap;
        word-break: keep-all;
        font-weight: 500;
        line-height: 18px;
        > .description {
          font-size: 12px;
          margin-top: 8px;
          font-weight: 300;
        }
      }

      > .amount {
        text-align: right;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }
      > .error {
        color: red;
        text-align: right;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }
    }
  }

  > .btn {
    display: flex;
    flex-grow: 1;
    line-height: 40px;
    font-size: 14px;
    font-weight: 600;
    border-top: inherit;
    > div {
      cursor: pointer;
      color: #202020;
      flex-grow: 1;
      flex-basis: 80px;
      text-align: center;
      border-left: 1px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
      :first-child {
        border-left: none;
        border-right: none;
      }
      :last-child {
        border-right: none;
      }
    }

    > .pay {
      font-size: 16px;
      font-weight: 600;
      color: #007aff;
    }
  }
`;
