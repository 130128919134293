import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { filter, find, get, groupBy, isEmpty, sortBy, sumBy } from "lodash";
import { useForm } from "../../toolbox";
import {
    DELETE_RESERVATION,
    GET_ALL_CONTRACTS_CALENDAR,
    GET_MY_CONTRACTS_CALENDAR,
    GET_RESERVATION_REQUESTS,
    GET_RESERVATIONS,
    GET_TRIPS,
} from "../../query/calendarQuery";
import {
    calcNeedToken,
    calcTripHipassSum,
    calcTripKmSum,
    calcUsedToken,
    calcWinner,
    canRequest,
    isShowContractCalendar,
    toReservationDate,
} from "../../toolbox/calendar";
import { getNextMonthLastDay } from "../../toolbox/day";
import {
    calcTimeRemain,
    commaNum,
    filterRange,
    getDayContract,
    getGuestContract,
    getLoginLink,
    getUseTypeTxt,
    kmFormat,
    reformGMT,
} from "../../toolbox/format";
import { MyCalendar } from "./component/MyCalendar";
import { SERVER_ADDRESS } from "../../index";
import axios from "axios";
import styled, { css } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { loadingAction } from "../../redux/loadingReducer";
import { getNow, isLater } from "../../toolbox/date";
import { DetailPopup, ReservationRequestDetailPopup } from "./TripHistoryPage";
import { isAdmin } from "../../toolbox/logic";
import { flatEntity } from "../../toolbox/query";
import InfoIcon from "@mui/icons-material/Info";
import { Dialog } from "@mui/material";
import ReactMarkdown from "react-markdown";

export const VehicleSelect = styled.div`
    padding-left: 20px;
    display: flex;
    overflow: auto;
    > .selected-vehicle {
        padding: 4px;
        background-color: black;
        color: white;
        border-radius: 4px;
        margin: 4px;
        cursor: pointer;
    }
    > .unselected-vehicle {
        padding: 4px;
        background-color: #999999;
        color: white;
        border-radius: 4px;
        margin: 4px;
        cursor: pointer;
    }

    ${(props) =>
        !props.visible &&
        css`
            display: none;
        `}
`;

export function CalendarPage(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);

    const { form, onChange, resetValue } = useForm();
    const [reservations, setReservations] = useState([]);
    const [trips, setTrips] = useState([]);
    const [contracts, setContracts] = useState(null);
    const [reservationRequests, setReservationRequests] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(moment());

    const [guideOpen, setGuideOpen] = useState(false);
    const [paymentDetailOpen, setPaymentDetailOpen] = useState(false);
    const [detailOpen, setDetailOpen] = useState(false);
    const [detailDate, setDetailDate] = useState(null);
    const [requestOpen, setRequestOpen] = useState(false);

    const [deleteReservation] = useMutation(DELETE_RESERVATION);
    const { data: userContracts, refetch: refetchContracts } = useQuery(
        GET_MY_CONTRACTS_CALENDAR,
        {
            variables: {
                id: tokenInfo?.id,
            },
            fetchPolicy: "cache-first",
        }
    );

    const { data: adminContracts, refetch: refetchAdmin } = useQuery(
        GET_ALL_CONTRACTS_CALENDAR,
        {
            variables: {
                endDate: moment
                    .tz("Asia/Seoul")
                    .subtract(2, "month")
                    .format("YYYY-MM-DD"),
            },
            fetchPolicy: "cache-first",
            skip: !isAdmin(tokenInfo?.id),
        }
    );

    const {
        loading: loading1,
        data: reservationData,
        refetch: refetchReservations,
    } = useQuery(GET_RESERVATIONS, {
        variables: {
            vehicleId: form?.vehicle,
            contract: form?.contract,
            startDate: get(form, "date[0]"),
            endDate: get(form, "date[1]"),
        },
        pollInterval: 300000,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        skip: !form?.vehicle || !get(form, "date[0]") || !get(form, "date[1]"),
        onCompleted: (data) => {
            // dispatch(loadingAction.loading(false));
        },
    });

    const {
        loading: loading2,
        data: tripData,
        refetch: refetchTrips,
    } = useQuery(GET_TRIPS, {
        variables: {
            vehicleId: form?.vehicle,
            contract: form?.contract,
            startDate: get(form, "date[0]"),
            endDate: get(form, "date[1]"),
        },
        pollInterval: 300000,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        skip: !form?.vehicle || !get(form, "date[0]") || !get(form, "date[1]"),
        onCompleted: (data) => {
            // dispatch(loadingAction.loading(false));
        },
    });

    const {
        loading: loading3,
        data: requestData,
        refetch: refetchRequest,
    } = useQuery(GET_RESERVATION_REQUESTS, {
        variables: {
            vehicleId: form?.vehicle,
            contract: form?.contract,
            startDate: get(form, "date[0]"),
            endDate: get(form, "date[1]"),
        },
        pollInterval: 300000,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        skip: !form?.vehicle || !get(form, "date[0]") || !get(form, "date[1]"),
        onCompleted: (data) => {
            // dispatch(loadingAction.loading(false));
        },
    });

    const refetch = useCallback(() => {
        refetchReservations().then(() => {});
        refetchTrips().then(() => {});
        refetchRequest().then(() => {});
    }, [refetchReservations, refetchTrips, refetchRequest]);

    const selectedContract = useMemo(() => {
        return find(contracts, (c) => c.id === form.contract);
    }, [contracts, form.contract]);

    const opponentContract = useMemo(() => {
        let contracts = selectedContract?.vehicle?.contracts;
        let start = selectedMonth.clone().startOf('month').format("YYYY-MM-DD");
        let end = selectedMonth.clone().endOf('month').format("YYYY-MM-DD");
        let dayContracts = filterRange(contracts, start, end);
        return find(dayContracts, (c) => c.id !== selectedContract.id);
    }, [selectedContract, selectedMonth]);

    const tripKmSum = useMemo(() => {
        return calcTripKmSum(trips, selectedMonth, selectedContract?.id);
    }, [trips, selectedMonth, selectedContract]);

    const tripHipassSum = useMemo(() => {
        return calcTripHipassSum(trips, selectedMonth, selectedContract?.id);
    }, [trips, selectedMonth, selectedContract]);

    const opponentTripKmSum = useMemo(() => {
        return calcTripKmSum(trips, selectedMonth, opponentContract?.id);
    }, [trips, selectedMonth, opponentContract]);

    const opponentTripHipassSum = useMemo(() => {
        return calcTripHipassSum(trips, selectedMonth, opponentContract?.id);
    }, [trips, selectedMonth, opponentContract]);

    useEffect(() => {
        if (loading1 || loading2 || loading3) {
            dispatch(loadingAction.loading(true));
        } else {
            dispatch(loadingAction.loading(false));
        }
    }, [loading1, loading2, loading3]);

    useEffect(() => {
        if (isAdmin(tokenInfo?.id)) {
            if (adminContracts) {
                let _contracts = get(adminContracts, "contracts.data", []);
                _contracts = _contracts.map((c) => flatEntity(c));
                _contracts = filter(_contracts, (c) => c.vehicle);
                _contracts = filter(_contracts, (c) =>
                    isShowContractCalendar(c)
                );
                setContracts(sortBy(_contracts, ["vehicle.numberPlate"]));
            }
        } else {
            if (userContracts) {
                let _contracts = get(
                    userContracts,
                    "usersPermissionsUser.data.attributes.contracts.data",
                    []
                );
                _contracts = _contracts.map((c) => flatEntity(c));
                _contracts = filter(
                    _contracts,
                    (c) =>
                        (!c?.endDate || !isLater(getNow(), c?.endDate)) &&
                        !c?.vehicle?.draftVehicle
                );
                if (_contracts.length === 0) {
                    alert("이용중인 차량이 없습니다");
                }
                setContracts(sortBy(_contracts, ["vehicle.numberPlate"]));
            }
        }
    }, [userContracts, adminContracts]);

    useEffect(() => {
        if (contracts && contracts.length > 0) {
            onChange([
                { id: "vehicle", value: contracts[0].vehicle.id },
                { id: "contract", value: contracts[0].id },
            ]);
        }
    }, [contracts]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, []);

    useEffect(() => {
        let rs = get(reservationData, "reservations.data", []);
        rs = rs.map((r) => flatEntity(r));
        setReservations(
            sortBy(
                rs.map((r) => _reformReservation(r, rs)),
                ["useDate", "createdAt"]
            )
        );
    }, [reservationData]);

    useEffect(() => {
        let trips = get(tripData, "trips.data", []);
        setTrips(
            sortBy(
                trips.map((trip) => _reformTrip(flatEntity(trip))),
                ["date", "description"]
            )
        );
    }, [tripData]);

    useEffect(() => {
        let requestDatas = get(requestData, "reservationRequests.data", []);
        setReservationRequests(
            sortBy(
                requestDatas.map((r) => _reformRequest(flatEntity(r))),
                ["useDate"]
            )
        );
    }, [requestData]);

    useEffect(() => {
        const calendarTrips = filter(
            _reformCalendarTrips(trips),
            (e) => e.start < form.now
        );
        // const calendarReservations = filter(reservations, (r) => r.start >= form.now);
        let result = calendarTrips.concat(reservations);
        for (const t of calendarTrips) {
            if (!find(reservations, (event) => event.start === t.start)) {
                if (
                    !find(
                        result,
                        (r) =>
                            r.start === t.start && r.title.startsWith("미지정")
                    )
                ) {
                    result.unshift(
                        renderEvent(
                            {},
                            "#DDDDDD",
                            `미지정\n(${calcNeedToken(t.start)}개)`,
                            t.start
                        )
                    );
                }
            }
        }
        result = result.concat(reservationRequests);
        setCalendarEvents(result);
    }, [form.now, trips, reservations, reservationRequests]);

    const getReservedReservation = useCallback(
        (date) => {
            return find(
                reservations,
                (r) => r?.contract?.id === form.contract && r.useDate === date
            );
        },
        [reservations, form.contract]
    );

    const guestContract = useMemo(() => {
        if (selectedContract?.contractType === 'DOORICAR') {
            return selectedContract;
        } else if (opponentContract?.contractType === 'DOORICAR') {
            return opponentContract;
        }
        return null;
    }, [selectedContract, opponentContract]);

    const tokenRate = useMemo(() => {
        if (!guestContract) {
            return 1;
        }
        let tokenRate = 1;
        const totalCount = selectedMonth.daysInMonth();
        let useCount = totalCount;

        let startMoment = moment.tz(selectedMonth.format("YYYY-MM-DD"), "Asia/Seoul").startOf('month');
        let endMoment = moment.tz(selectedMonth.format("YYYY-MM-DD"), "Asia/Seoul").endOf('month');
        if (guestContract.startDate
            && moment.tz(guestContract.startDate, "Asia/Seoul").format("YYYY-MM") === selectedMonth.format("YYYY-MM")
            && guestContract.startDate > startMoment.format("YYYY-MM-DD")) {
            useCount -= moment.tz(guestContract.startDate, "YYYY-MM-DD", "Asia/Seoul").date() - 1;
        }

        if (guestContract.endDate
            && moment.tz(guestContract.endDate, "Asia/Seoul").format("YYYY-MM") === selectedMonth.format("YYYY-MM")
            && guestContract.endDate < endMoment.format("YYYY-MM-DD")) {
            useCount -= totalCount - moment.tz(guestContract.endDate, "YYYY-MM-DD", "Asia/Seoul").date();
        }

        if (useCount === totalCount) {
            tokenRate = 1;
        } else {
            tokenRate = useCount / totalCount;
        }
        return tokenRate;
    }, [guestContract, selectedMonth]);

    const tokenAmount = useMemo(() => {
        const contract = selectedContract;
        if (!contract?.tokenAmount) {
            return Math.ceil(15 * tokenRate);
        }
        if (contract?.tokenAmount === 100) {
            return 100;
        }
        return Math.ceil(contract.tokenAmount * tokenRate);
    }, [selectedContract, tokenRate]);

    const opponentTokenAmount = useMemo(() => {
        if (!opponentContract?.tokenAmount) {
            return Math.ceil(15 * tokenRate);
        }
        if (opponentContract?.tokenAmount === 100) {
            return 100;
        }
        return Math.ceil(opponentContract.tokenAmount * tokenRate);
    }, [opponentContract, tokenRate]);

    const remainToken = useMemo(() => {
        if (isEmpty(form.contract)) {
            return 0;
        }
        return tokenAmount - calcUsedToken(reservations, selectedMonth, form.contract);
    }, [tokenAmount, selectedMonth, form.contract, reservations]);

    const opponentRemainToken = useMemo(() => {
        if (isEmpty(opponentContract)) {
            return 0;
        }
        return opponentTokenAmount - calcUsedToken(reservations, selectedMonth, opponentContract.id);
    }, [opponentTokenAmount, selectedMonth, opponentContract, reservations]);

    const remainTokenTxt = useMemo(() => {
        if (!form.contract) {
            return "";
        }
        const remain = remainToken;
        if (remain < 0) {
            return `사용한 예약권 : ${calcUsedToken(
                reservations,
                selectedMonth,
                form.contract
            )}개\n(사용한 유료 예약권 : ${remain * -1}개)`;
        } else {
            return `사용한 예약권 : ${calcUsedToken(
                reservations,
                selectedMonth,
                form.contract
            )}개\n(남은 무료 예약권 : ${tokenAmount === 100? '무제한': `${remain}개`})`;
        }
    }, [remainToken, selectedMonth, form.contract, reservations, tokenAmount]);

    const opponentRemainTokenTxt = useMemo(() => {
        if (!opponentContract) {
            return "";
        }
        const remain = opponentRemainToken;
        if (remain < 0) {
            return `상대가 사용한 예약권 : ${calcUsedToken(
                reservations,
                selectedMonth,
                opponentContract.id
            )}개\n(사용한 유료 예약권 : ${remain * -1}개)`;
        } else {
            return `상대가 사용한 예약권 : ${calcUsedToken(
                reservations,
                selectedMonth,
                opponentContract.id
            )}개\n(남은 무료 예약권 : ${remain}개)`;
        }
    }, [opponentRemainToken, selectedMonth, opponentContract, reservations]);

    const tokenPriceTxt = useMemo(() => {
        const usedToken = Math.max(remainToken * -1, 0);
        return `유료 예약권 비용 : ${commaNum(selectedContract?.tokenPrice)}원 X ${usedToken}개 = ${commaNum(selectedContract?.tokenPrice * usedToken)}원`;
    }, [selectedContract?.tokenPrice, remainToken]);

    const opponentTokenPriceTxt = useMemo(() => {
        const usedToken = Math.max(opponentRemainToken * -1, 0);
        return `상대 예약권 비용 : ${commaNum(opponentContract?.tokenPrice)}원 X ${usedToken}개 = ${commaNum(opponentContract?.tokenPrice * usedToken)}원`;
    }, [opponentContract?.tokenPrice, opponentRemainToken]);

    const useType = useMemo(() => {
        const contract = selectedContract;
        if (contract?.useType) {
            return `매칭유형 : ${getUseTypeTxt(contract.useType)}`;
        }
        return "";
    }, [selectedContract]);

    const renderEvent = useCallback((e, color, title, useDate, lineThrough) => ({
        ...e,
        title: title,
        start: useDate,
        backgroundColor: color,
        borderColor: color,
        classNames: lineThrough ? ["loser"] : [],
    }), []);

    const _reformReservation = useCallback(
        (r, rs) => {
            const dates = groupBy(rs, (reservation) => reservation.useDate);
            const date = dates[r.useDate];
            let winnerId = date[calcWinner(date)].id;

            let title =
                r?.contract?.users_permissions_user?.nickname ||
                r?.contract?.users_permissions_user?.realname ||
                "이름없음";
            if (r.contract?.id === form.contract) {
                title = "나";
            }

            const needToken = calcNeedToken(r.useDate, r.priority);
            if (needToken) {
                title += `\n(${needToken}개)`;
            }

            let color;
            if (winnerId && winnerId !== r.id) {
                color = "#DDDDDD";
            } else {
                if (!r.contract) {
                    color = "#0F80FF";
                } else {
                    color =
                        r.contract?.id === form.contract
                            ? "#2B9E90"
                            : "#FA8128";
                }
            }
            return renderEvent(r, color, title, r.useDate, color === "#DDDDDD");
        },
        [form.contract]
    );

    const _reformCalendarTrips = useCallback(
        (trips) => {
            let result = [];
            let tripGroups = groupBy(trips, (t) => {
                return toReservationDate(t) + t?.contract?.id;
            });
            for (let tripGroupKey in tripGroups) {
                const tripGroup = tripGroups[tripGroupKey];
                const kmGroup = filter(tripGroup, (t) => t.km);
                const kmSum = kmFormat(sumBy(kmGroup, (t) => t.km));
                if (kmSum) {
                    result.push(
                        _reformTrip({
                            ...tripGroup[0],
                            km: kmSum,
                            hipass: undefined,
                        })
                    );
                }
            }
            for (let tripGroupKey in tripGroups) {
                const tripGroup = tripGroups[tripGroupKey];
                const hipassGroup = filter(tripGroup, (t) => t.hipass);
                const hipassSum = sumBy(hipassGroup, (t) => t.hipass);
                if (hipassSum) {
                    result.push(
                        _reformTrip({
                            ...tripGroup[0],
                            km: undefined,
                            hipass: hipassSum,
                        })
                    );
                }
            }
            return result;
        },
        [form.contract]
    );

    const _reformTrip = useCallback(
        (trip) => {
            let title = `${trip.km}km`;
            if (trip.hipass) {
                title = `${trip.hipass}원`;
            }
            let color;
            if (trip?.contract?.id === form.contract) {
                color = "#2B9E90";
            } else if (trip?.contract) {
                color = "#FA8128";
            } else {
                color = "#DDDDDD";
            }

            const reformTime = (t) => {
                if (t.length === 7) {
                    return "0" + t;
                }
                return t;
            };
            return renderEvent(
                {
                    ...trip,
                    description: `${reformTime(
                        trip.meta.시작시간
                    )} ~ ${reformTime(trip.meta.종료시간)}`,
                    useDate: toReservationDate(trip),
                },
                color,
                title,
                toReservationDate(trip)
            );
        },
        [form.contract]
    );

    const _reformRequest = useCallback(
        (req) => {
            let title = `${
                req.autoAccept ? "운행" : "양도"
            } 요청\n(${calcTimeRemain(req?.expireAt)}후 자동 ${
                req.autoAccept ? "수락" : "거절"
            })`;
            let color;
            if (req?.contract?.id === form.contract) {
                color = "#2B9E90";
            } else if (req?.contract) {
                color = "#FA8128";
            } else {
                color = "#DDDDDD";
            }
            return renderEvent(req, color, title, req.useDate);
        },
        [form.contract]
    );

    const canAddReservation = useCallback(
        (dateStr) => {
            const dateMoment = moment.tz(dateStr, "Asia/Seoul");
            if (
                dateMoment.format("YYYY-MM") !== selectedMonth.format("YYYY-MM")
            ) {
                // 보고있는 달의 예약만 가능하다
                return false;
            }

            if (isAdmin(tokenInfo?.id)) {
                return true;
            }

            const nextMoment = dateMoment.clone();
            nextMoment.add(2, "day");
            const nowMoment = moment.tz("Asia/Seoul");
            nowMoment.add(6, "hour");
            if (getNextMonthLastDay() < dateMoment) {
                alert("다음달까지만 예약이 가능합니다.");
                return false;
            } else if (nowMoment > nextMoment) {
                alert("이미 지나간 날짜입니다.");
                return false;
            }

            // 토큰 제한 해제
            // const remainToken = remainToken(reservations);
            // if (nowMoment < dateMoment) {
            //     const needToken = calcNeedToken(dateStr);
            //     if (remainToken < needToken) {
            //         alert('예약권이 부족합니다. 당일 예약을 이용해주세요.');
            //         return false;
            //     }
            // }

            return true;
        },
        [remainToken, tokenAmount, selectedMonth, form.contract]
    );

    const canDeleteReservation = useCallback(
        (r) => {
            // 예약 30분 이내, 예약 확정 이전에만 취소 가능
            const nowMoment = moment.tz("Asia/Seoul");
            const dateMoment = moment.tz(r.useDate, "Asia/Seoul");
            dateMoment.date(1);
            dateMoment.subtract(6, "hour");
            if (dateMoment > nowMoment) {
                return true;
            }

            if (!isAdmin(tokenInfo?.id)) {
                const created = moment.tz(r.createdAt, "Asia/Seoul");
                created.add(30, "minute");
                if (nowMoment < created) {
                    return true;
                }
                alert(
                    "예약 30분이내 또는 예약 확정 이전에만 취소가 가능합니다."
                );
                return false;
            }
            return true;
            // const created = moment.tz(r.createdAt, 'Asia/Seoul');
            // created.add(30, 'minute');
            // if (nowMoment < created) {
            //     return true;
            // }
            // alert('예약 30분이내 또는 예약 확정 이전에만 취소가 가능합니다.')
            // return false;
        },
        [form.contract]
    );

    const onDeleteReservation = useCallback(
        async (reservationId) => {
            const deleteTarget = find(
                reservations,
                (r) => r.id === reservationId
            );
            if (!canDeleteReservation(deleteTarget)) {
                return;
            }
            try {
                dispatch(loadingAction.loading(true));
                await deleteReservation({
                    variables: {
                        id: reservationId,
                    },
                });
                await refetch();
            } catch (err) {
                dispatch(loadingAction.loading(false));
            }
        },
        [reservations]
    );

    const onAddOrDeleteReservation = useCallback(
        async (date) => {
            //reserved?
            const reserved = getReservedReservation(date);
            if (reserved?.id) {
                if (isAdmin(tokenInfo?.id)) {
                    if (
                        window.confirm(`예약을 취소하시겠습니까? (관리자 모드)`)
                    ) {
                        await onDeleteReservation(reserved.id);
                        await refetch();
                    }
                } else {
                    await onDeleteReservation(reserved.id);
                    await refetch();
                }
                return;
            }

            if (!canAddReservation(date)) {
                return;
            }

            const contractId = form?.contract;
            try {
                if (!isEmpty(contractId)) {
                    dispatch(loadingAction.loading(true));

                    const contract = selectedContract;

                    const dayReservation = filter(
                        reservations,
                        (r) => r.useDate === date
                    );
                    // const dayRequest = find(reservations, r => (r.useDate === date));
                    const dayRequest = [];
                    const can = canRequest(
                        contractId,
                        contract,
                        date,
                        dayReservation,
                        dayRequest
                    );

                    if (isAdmin(tokenInfo?.id)) {
                        if (
                            window.confirm(
                                `예약을 생성하시겠습니까? (관리자 모드)`
                            )
                        ) {
                            const res = await axios.post(
                                SERVER_ADDRESS + "/api/reservations/dooricar",
                                {
                                    contractId: contractId,
                                    useDate: date,
                                }
                            );
                        }
                    } else if (can.result === "OK") {
                        if (
                            window.confirm(
                                `상대에게 ${
                                    can.autoAccept ? "운행" : "예약 양도"
                                }요청을 보내시겠습니까?`
                            )
                        ) {
                            const res = await axios.post(
                                SERVER_ADDRESS +
                                    "/api/reservation-requests/makeRequest",
                                {
                                    contractId: contractId,
                                    useDate: date,
                                }
                            );
                        }
                    } else if (can.result === "RESERVATION") {
                        const res = await axios.post(
                            SERVER_ADDRESS + "/api/reservations/dooricar",
                            {
                                contractId: contractId,
                                useDate: date,
                            }
                        );
                    } else {
                        alert(can.msg);
                    }
                    await refetch();
                } else {
                    alert(
                        "계약정보를 처리함에 있어 오류가 발생하였습니다.\n오류가 반복된다면, 관리자에게 문의해주세요."
                    );
                }
            } catch (err) {
                console.log(err);
                alert("오류가 발생하였습니다.");
            }
        },
        [
            remainToken,
            tokenAmount,
            selectedMonth,
            form.contract,
            reservations,
            tokenInfo,
            refetch,
            onDeleteReservation,
            selectedContract,
            getReservedReservation,
            canAddReservation,
        ]
    );

    const onSetMonthDates = useCallback(
        (payload) => {
            setReservations([]);
            let now = moment.tz("Asia/Seoul");
            now.add(-6, "hour");

            let month = moment.tz(payload.start, "Asia/Seoul");
            month.add(10, "day");
            setSelectedMonth(month);
            onChange({
                id: "date",
                value: [reformGMT(payload.start), reformGMT(payload.end)],
            });
            onChange({
                id: "now",
                value: reformGMT(now),
            });
        },
        [onChange]
    );

    const _handleClick = useCallback(
        (dateStr) => {
            if (dateStr < form.now) {
                setDetailDate(dateStr);
                setDetailOpen(true);
            } else {
                if (!isEmpty(form.vehicle)) {
                    const req = find(
                        reservationRequests,
                        (r) => r.useDate === dateStr
                    );
                    if (req) {
                        setDetailDate(dateStr);
                        setRequestOpen(true);
                    } else {
                        onAddOrDeleteReservation(dateStr);
                    }
                } else {
                    alert("차량을 먼저 선택해주세요");
                }
            }
        },
        [onAddOrDeleteReservation, reservationRequests]
    );

    const onClickDate = useCallback(
        (date) => {
            _handleClick(date.dateStr);
        },
        [_handleClick]
    );

    const onClickEvent = useCallback(
        (event) => {
            const dateStr = event.event.startStr;
            if (!dateStr.includes("T")) {
                _handleClick(dateStr);
            }
        },
        [_handleClick]
    );

    const paymentDetail = useMemo(() => {
        const texts = [
            useType,
            `\n내 하이패스 요금 : ${tripHipassSum}원`,
        ];
        if (selectedContract?.kmCharge) {
            texts.push(`\n내 거리당요금 : ${tripKmSum}km X ${selectedContract?.kmCharge}원 = ${commaNum(tripKmSum * selectedContract?.kmCharge)}원`);
        } else {
            texts.push(`\n내 주행거리 : ${tripKmSum}km`);
        }

        if (tokenAmount !== 100 && tokenPriceTxt) {
            texts.push(`${tokenPriceTxt}`);
        }

        if (opponentContract) {
            if (opponentContract?.kmCharge) {
                texts.push(`\n상대 거리당요금 : ${opponentTripKmSum}km X ${opponentContract?.kmCharge}원 = ${commaNum(opponentTripKmSum * opponentContract?.kmCharge)}원`);
            } else {
                texts.push(`\n상대 주행거리 : ${opponentTripKmSum}km`);
            }
            if (opponentTokenAmount !== 100 && opponentTokenPriceTxt) {
                texts.push(opponentTokenPriceTxt);
            }
        }
        return texts.join('\n');
    }, [useType, tripKmSum, selectedContract?.kmCharge, tripHipassSum, opponentTripKmSum, opponentContract?.kmCharge, tokenPriceTxt, opponentTokenPriceTxt, tokenAmount, opponentTokenAmount]);

    return (
        <>
            <div className="my-page_wrap">
                <VehicleSelect visible={contracts?.length > 1}>
                    {contracts?.map((c) => (
                        <div
                            key={c.id}
                            className={
                                form?.contract === c.id
                                    ? "selected-vehicle"
                                    : "unselected-vehicle"
                            }
                            onClick={() => {
                                onChange([
                                    { id: "vehicle", value: c.vehicle.id },
                                    { id: "contract", value: c.id },
                                ]);
                            }}
                        >
                            {c.vehicle.model} ({c.vehicle.numberPlate}){" "}
                            {isAdmin(tokenInfo?.id)
                                ? `(${
                                      c?.users_permissions_user?.realname ??
                                      "이름없음"
                                  } ${
                                      c.contractType === "DOORICAR"
                                          ? "게스트"
                                          : "호스트"
                                  })`
                                : ""}
                        </div>
                    ))}
                </VehicleSelect>
                {/*<TopNavigator />*/}
                {/*<div className="page_filter">*/}
                {/*    <DataComponent*/}
                {/*        type="select"*/}
                {/*        id="vehicle"*/}
                {/*        form={form}*/}
                {/*        onChange={onChange}*/}
                {/*        optionList={vehicleOptionList}*/}
                {/*        placeholder="차량을 선택해주세요"*/}
                {/*    />*/}
                {/*</div>*/}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "right",
                        padding: "10px",
                        cursor: "pointer",
                        marginTop: "8px",
                        gap: "8px",
                    }}
                >
                    <div
                        onClick={() => setPaymentDetailOpen(true)}
                        style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "4px",
                            padding: "6px",
                        }}
                    >
                        <InfoIcon fontSize="14px" /> 정산 상세보기
                    </div>
                    <div
                        onClick={() => setGuideOpen(true)}
                        style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            border: "1px solid black",
                            borderRadius: "4px",
                            padding: "6px",
                        }}
                    >
                        <InfoIcon fontSize="14px" /> 캘린더 이용안내
                    </div>
                </div>
                {isAdmin(tokenInfo?.id) && <div style={{whiteSpace: "pre-wrap", textAlign: "center", paddingRight: "16px", marginTop: "8px", marginBottom: "8px"}}>
                    {paymentDetail}
                </div>}
                <div
                    style={{
                        textAlign: "center",
                        paddingRight: "16px",
                        marginTop: "8px",
                        marginBottom: "8px",
                    }}
                >
                    {remainTokenTxt}
                </div>
                <MyCalendar
                    reservations={calendarEvents}
                    onClickDate={onClickDate}
                    onClickEvent={onClickEvent}
                    onSetMonthDates={onSetMonthDates}
                />
            </div>
            <GuideDialog open={guideOpen} onClose={() => setGuideOpen(false)} />
            <DetailPopup
                open={detailOpen}
                onClose={() => setDetailOpen(false)}
                dateStr={detailDate}
                vehicleId={form?.vehicle}
                reservations={reservations}
                trips={trips}
                refetch={refetch}
                contracts={selectedContract?.vehicle?.contracts}
            />
            <ReservationRequestDetailPopup
                open={requestOpen}
                onClose={() => setRequestOpen(false)}
                dateStr={detailDate}
                reservationRequests={reservationRequests}
                refetch={refetch}
                contract={selectedContract}
            />
            <PaymentDetailDialog open={paymentDetailOpen} onClose={() => setPaymentDetailOpen(false)} paymentDetail={paymentDetail} />
        </>
    )
}

const PaymentDetailDialog = ({ open, onClose, paymentDetail }) => {
    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <div style={{padding: "16px", whiteSpace: "pre-wrap", lineHeight: "120%"}}>{paymentDetail}</div>
    </Dialog>
}

const GuideDialog = ({ open, onClose }) => {
    const payload =
        "💡 **[캘린더 이용방법 안내]**\n" +
        "\n" +
        "호스트, 게스트 간 사용일정 관리와 정산이 모두 캘린더를 통해 이뤄지므로 사용이 필수이며 (캘린더 링크 단톡방 공지사항 게시), 각 매칭유형 별로 캘린더 사용방법이 상이합니다. \n" +
        "\n" +
        "* **캘린더 하루단위 :** 오전 06시~다음날 06시\n" +
        "\n" +
        "* **예약권이란? :** 차를 사용할 날을 미리(또는 당일에) 예약할 때 사용됩니다. 평일예약 시 1개, 주말 및 공휴일 예약 시 2개가 소모됩니다. \n" +
        "\n" +
        "\n" +
        "**1. 호스트우선형**\n" +
        "\n" +
        "게스트는 희망날짜를 클릭하여 호스트에게 ‘운행요청’을 해야합니다. 호스트가 24시간 동안 무응답 시 수락한 것으로 보아 게스트로 예약이 이뤄집니다. \n" +
        "\n" +
        "호스트는 캘린더에 따로 예약을 할 필요가 없으며, 차를 꼭 사용해야하는 날은 미리 예약을 하여 게스트로부터 운행요청을 받지 않겠다는 의미로 활용이 가능합니다. (호스트가 이미 예약한 날에 대하여 게스트가 운행요청을 하는 경우에는 24시간 내 무응답 시 거절된 것으로 봄.)\n" +
        "\n" +
        "게스트는 10개의 무료예약권을 보유합니다. 호스트는 게스트가 보유한 무료예약권 10개를 전부 사용하도록 보장해야합니다. 게스트가 10개의 무료예약권을 초과하여 사용 시에는 유료예약권이 소모됩니다. 유료예약권 사용에 따른 비용은 호스트에게 지급됩니다. (유료예약권 1개 당 비용 = 월이용료 / 10)\n" +
        "\n" +
        "\n" +
        // "**2. 요일지정형**\n" +
        // "\n" +
        // "호스트, 게스트 모두 우선권 없이 선착순으로 예약하여 이용합니다. 호스트와 게스트는 각자 15개의 무료예약권을 보유합니다. (게스트 입금일 다음날 오전 10시부터 캘린더 예약이 가능해요.)\n" +
        // "\n" +
        // "호스트와 게스트가 미리 고정적으로 선택한 요일은 자동으로 사전예약이 이뤄집니다. (사전예약 시 사용된 예약권은 유료예약권 계산에 산입되지 않습니다.)  \n" +
        // "\n" +
        // "호스트/게스트가 15개의 무료예약권을 초과하여 사용 시에는 유료예약권이 소모됩니다. \n" +
        // "유료예약권 사용에 따른 비용은 상대방에게 지급됩니다. (유료예약권 1개 당 비용 = 월이용료 / 15)\n" +
        // "\n" +
        // "\n" +
        "**2. 게스트우선형**\n" +
        "\n" +
        "게스트는 캘린더에 따로 예약할 필요가 없으며, 차를 꼭 사용해야하는 날은 미리 예약을 하여 호스트로부터 운행요청을 받지 않겠다는 의미로 활용이 가능합니다.\n" +
        "\n" +
        "호스트는 희망날짜를 클릭하여 ‘운행요청’을 할 수 있으며, 게스트가 24시간 동안 무응답 시 수락한 것으로 보아 예약이 이뤄집니다. (게스트가 이미 예약을 해놓은 날에 대하여 운행요청을 하는 경우에는 24시간 내 무응답 시 거절된 것으로 봄.)\n" +
        "\n" +
        "호스트는 무료예약권을 보유하지 않으며 유료예약권 사용만 가능합니다.호스트의 유료예약권 사용에 따른 비용은 게스트에게 지급됩니다. (유료예약권 1개 당 비용 = 월이용료 / 10)\n" +
        "\n" +
        "\n" +
        "**※ 같은 날에 호스트, 게스트가 모두 이용 시 처리방법**\n" +
        "\n" +
        "하루에 호스트와 게스트가 모두 이용 시, 더 많이 사용한 분의 이름으로 예약해주시고 다음날 캘린더에서 각자 운행시각 별로 운전자를 지정(변경)해주세요.\n" +
        "\n" +
        "\n" +
        "**※ 예약취소에 관한 규정**\n" +
        "\n" +
        "예약 후 30분 경과 시 예약 취소가 불가능합니다.\n" +
        "\n" +
        "\n" +
        "**※ 예약변경(=운행요청 수락)에 관한 규정**\n" +
        "\n" +
        "호스트, 게스트 모두 상대방이 예약한 요일을 클릭하여 ‘운행요청’을 할 수 있습니다. 상대방이 나의 운행요청을 수락할 경우 예약자가 본인으로 변경되며, 상대방이 사용했던 예약권은 복원됩니다.";
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <_GuideDialog>
                <ReactMarkdown
                    children={payload?.replace(/\n/gi, "&nbsp;\n\n")}
                />
            </_GuideDialog>
        </Dialog>
    );
};

const _GuideDialog = styled.div`
    padding: 16px;
    line-height: 120%;
`;
